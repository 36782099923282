import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from './images/index.png'

function Header({page}) {
	const location = useLocation();
    return (
		<div id="canvas">
			<div id="box_wrapper">
				<div class={location.pathname !== "/" ? "header_absolute ds cover-background": ""}>
					<section class="page_topline ds c-my-10 s-overlay">
						<div class="container">
							<div class="row align-items-center">
								<div class="col-12 col-sm-5 text-left">
									<span class="social-icons">

										<a href="#" class="fa fa-facebook " title="facebook"></a>
										<a href="#" class="fab fa-youtube " title="youtube"></a>
										<a href="#" class="fab fa-linkedin-in " title="linkedin"></a>
										<a href="#" class="fa fa-twitter " title="twitter"></a>
										<a href="#" class="fa fa-google " title="google"></a>

									</span>
								</div>
								<div class="col-12 col-sm-7 text-right">

									<ul class="small-text">
										<li>
											<p class="phone_number"><span>Questions?</span><a href="tel:855374-6211">(254) 795 080 085</a></p>
										</li>
									</ul>


								</div>
							</div>
						</div>
					</section>

					<header class="page_header ds justify-nav-end">
						<div class="container">
							<div class="row align-items-center">
								<div class="col-xl-3 col-lg-4 col-md-5 col-11">
									<a href="index.html" class="logo">
										<img src={Logo} alt="" />
									</a>
								</div>
								<div class="col-xl-9 col-lg-8 col-md-7 col-1">
									<div class="nav-wrap">

										<nav class="top-nav">
											<ul class="nav sf-menu">
												<li>
													<NavLink to="/">Home</NavLink>
												</li>
												<li>
													<NavLink to="/about">About</NavLink>
												</li>
												<li>
													<NavLink to="/services">Services</NavLink>
												</li>
												<li>
													<NavLink to="/feestructure">Our Packages</NavLink>
												</li>

												<li>
													<NavLink to="/gallery">Gallery</NavLink>
												</li>

												<li>
													<NavLink to="/contacts">Contact us</NavLink>
												</li>
											</ul>


										</nav>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- header toggler --> */}
						<span class="toggle_menu"><span></span></span>
					</header>

					{/* check if it is home page and not to show the section */}
					{
						location.pathname != "/" ? (
							<section class="page_title padding-mobile cs s-pt-59 s-pb-94">
								<div class="container">
									<div class="row">


										<div class="col-md-12">
											<h1 class="bold">{page && page}</h1>
											<ul class="breadcrumb">
												<li class="breadcrumb-item">
													<Link to="/">Home</Link>
												</li>
												<li class="breadcrumb-item active">
													{page && page}
												</li>
											</ul>
										</div>


									</div>
								</div>
							</section>
						) : null
					}
					
					
				</div>
			</div>
			
		</div>
		
    )
}
export default Header;