import Footer from "../../layout/footer";
import Header from "../../layout/header";

function FeeStructure() {
    return (
        <>
            <Header page={'Our Packages'}/>
            <section class="ls s-py-75">
				<div class="container ">
                    <div class="text-center">
                        <h3><span class="color-main">Our</span> Packages</h3>
                        <p class="subtitle width-xl-60 width-100">The school offers the following services for Teenage first-time drivers, new adult learners and existing drivers with lapsed licenses.</p>
                    </div>
					<div class="row c-gutter-0">

						<div class="divider-70 d-none d-lg-block"></div>

						<div class="col-lg-4 col-md-4">
							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Motorcycle (A2)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">7500</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									4% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>15 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>No prior experience needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>
						</div>

                        <div class="col-lg-4 col-md-4">

							<div class="pricing-plan plan-featured box-shadow">
								<div class="plan-name">
									<h3>
										Tuk Tuk (A3)
									</h3>
								</div>
								{/* <div class="price-wrap">
									<span class="plan-price">10000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description color-darkgrey">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>15 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>1 year of experience in A2</li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>
                        
						<div class="col-lg-4 col-md-4 mx-sm-auto">

							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Light Vehicle (B)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">15000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>30 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>No prior experience needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>

						<div class="divider-40 d-none d-lg-block"></div>
					</div>

                    <div class="row c-gutter-0">

						<div class="divider-70 d-none d-lg-block"></div>

						<div class="col-lg-4 col-md-4">
							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Professional Drivers (B3)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">15000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									4% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>20 lessons</li>
										<li>Minimum age is 21 years</li>
										<li>4 years of experience needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>
						</div>

                        <div class="col-lg-4 col-md-4">

							<div class="pricing-plan plan-featured box-shadow">
								<div class="plan-name">
									<h3>
										Light Truck (C1)
									</h3>
								</div>
								{/* <div class="price-wrap">
									<span class="plan-price">17500</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description color-darkgrey">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>35 lessons</li>
										<li>Minimum age is 22 years</li>
										<li>2 year of experience in B</li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>
                        
						<div class="col-lg-4 col-md-4 mx-sm-auto">

							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Medium Truck (C)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">17500</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>35 lessons</li>
										<li>Minimum age is 25 years</li>
										<li>2 years experience in C1</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>

						<div class="divider-40 d-none d-lg-block"></div>
					</div>

                    <div class="row c-gutter-0">

						<div class="divider-70 d-none d-lg-block"></div>

						<div class="col-lg-4 col-md-4">
							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Prime Movers (CE)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">60000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									4% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>40 lessons</li>
										<li>Minimum age is 28 years</li>
										<li>5 years experience in C</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>
						</div>

                        <div class="col-lg-4 col-md-4">

							<div class="pricing-plan plan-featured box-shadow">
								<div class="plan-name">
									<h3>
										Matatu 14 Seater (D1)
									</h3>
								</div>
								{/* <div class="price-wrap">
									<span class="plan-price">20000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description color-darkgrey">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>15 lessons</li>
										<li>Minimum age is 22 years</li>
										<li>4 years of experience</li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>
                        
						<div class="col-lg-4 col-md-4 mx-sm-auto">

							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										MiniBus 33 Seater (D2)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">20000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>20 lessons</li>
										<li>Minimum age is 25 years</li>
										<li>3 years experience in D1</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>

						<div class="divider-40 d-none d-lg-block"></div>
					</div>

                    <div class="row c-gutter-0">

						<div class="divider-70 d-none d-lg-block"></div>

						<div class="col-lg-4 col-md-4">
							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Buses (D3)
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">20000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									4% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>20 lessons</li>
										<li>Minimum age is 30 years</li>
										<li>3 years experience in D2</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>
						</div>

                        <div class="col-lg-4 col-md-4">

							<div class="pricing-plan plan-featured box-shadow">
								<div class="plan-name">
									<h3>
										PSV Special
									</h3>
								</div>
								{/* <div class="price-wrap">
									<span class="plan-price">15000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description color-darkgrey">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li></li>
										<li>Minimum age is 30 years</li>
										<li>4 years of experience</li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>
                        
						<div class="col-lg-4 col-md-4 mx-sm-auto">

							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Defensive Driving
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">4000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>5 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>Valid drivers licence needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>

						<div class="divider-40 d-none d-lg-block"></div>
					</div>

                    <div class="row c-gutter-0">

						<div class="divider-70 d-none d-lg-block"></div>

						<div class="col-lg-4 col-md-4">
							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Refreshment course
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">5000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									4% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>10 lessons</li>
										<li></li>
										<li>Valid drivers licence needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>
						</div>

                        <div class="col-lg-4 col-md-4">

							<div class="pricing-plan plan-featured box-shadow">
								<div class="plan-name">
									<h3>
										Day Scholars Plant Operators
									</h3>
								</div>
								{/* <div class="price-wrap">
									<span class="plan-price">90000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description color-darkgrey">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
									<ul class="list-styled-caret">
										<li>160 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>Valid drivers licence needed</li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>
                        
						<div class="col-lg-4 col-md-4 mx-sm-auto">

							<div class="pricing-plan box-shadow">
								<div class="plan-name">
									<h3>
										Boarders Plant Operators
									</h3>
								</div>
								{/* <div class="price-wrap color-main">
									<span class="plan-price">100000</span><span class="plan-decimals">00</span><span class="plan-sign">kes</span>
								</div> */}
								{/* <div class="plan-description">
									0,2% per transaction
								</div> */}
								<div class="plan-features">
                                <ul class="list-styled-caret">
										<li>160 lessons</li>
										<li>Minimum age is 18 years</li>
										<li>Valid drivers licence needed</li>
									</ul>
								</div>
								<div class="plan-button">
									<a href="#" class="btn btn-outline-darkgrey">Get Started</a>
								</div>
							</div>

						</div>

						<div class="divider-40 d-none d-lg-block"></div>
					</div>
				</div>
			</section>
            <Footer />
        </>
    )
}
export default FeeStructure;