import WelcomeImage from '../images/welcome.jpg'
function Welcome() {
    return (
        <section id="welcome" class="ls">
            <div class="container">
                <div class="row">

                    <div class="divider-30 d-none d-xl-block"></div>

                    <div class="col-md-12">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="divider-50 d-lg-block"></div>
                                <h3 class="width-80 mt-0"><span class="color-main">Welcome</span> to ctcplantds Traffic School!</h3>
                                <p class="after-title subtitle">
                                    County to County Plant Machinery and Driving School has rapidly expanded its mouth-watering name in Kenya, since its ineption to become the trailblazers in the Plant Operator and driver training industry.
                                    Our roots began with a vision to provide excellent services in both Plant Operator and Driving to our customers and empower, equip the youth and all the trainees with excellent knowledge and work-related skills.
                                    We have grown over the years and have trained trainees from over 70% counties in our republic. We have both Plant and Motor vehicle mechanics trainees trained by our qualified, proficient and professional instructors.
                                    We are and NTSA accredied institution with professional instructors
                                </p>
                                <div id="accordion01" role="tablist">
                                    <div class="card">
                                        <div class="card-header" role="tab" id="collapse01_header">
                                            <h5>
                                                <a data-toggle="collapse" href="#collapse01" aria-expanded="true" aria-controls="collapse01">
                                                    Our Mission
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="collapse01" class="collapse show" role="tabpanel" aria-labelledby="collapse01_header" data-parent="#accordion01">
                                            <div class="card-body">
                                                To equip all road users and plant operators with valuable driving and operator skills, knowledge and attitude that will reduce road carnage site accidents.
                                                We are dedicated to assist all people within the nation and others towards fulfilling their growth needs specifically through driving and plant operator.
                                                We at County to County Plant Machinery and Driving School believe that our individual actions and attitudes play a great role in determining safety.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" id="collapse02_header">
                                            <h5>
                                                <a class="collapsed" data-toggle="collapse" href="#collapse02" aria-expanded="false" aria-controls="collapse02">
                                                    Our Motto
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse02" class="collapse" role="tabpanel" aria-labelledby="collapse02_header" data-parent="#accordion01">
                                            <div class="card-body">
                                                County to County is the partner of Excellence and Defensive Driving.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" id="collapse03_header">
                                            <h5>
                                                <a class="collapsed" data-toggle="collapse" href="#collapse03" aria-expanded="false" aria-controls="collapse03">
                                                    Our Physiology
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse03" class="collapse" role="tabpanel" aria-labelledby="collapse03_header" data-parent="#accordion01">
                                            <div class="card-body">
                                                We strongly believe in our philosophy that every person deserves an opportunity to a gainful employment through meanigful training.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" id="collapse04_header">
                                            <h5>
                                                <a class="collapsed" data-toggle="collapse" href="#collapse04" aria-expanded="false" aria-controls="collapse03">
                                                    NTSA New Curriculum
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse04" class="collapse" role="tabpanel" aria-labelledby="collapse03_header" data-parent="#accordion01">
                                            <div class="card-body">
                                                Train with the newly introduced NTSA curriculum and become a competent driver as you promote safety on our roads.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider-80 d-lg-block"></div>
                            </div>
                            <div class="col-lg-6 to-bottom-image">
                                <img src={WelcomeImage} alt="" />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}
export default Welcome;