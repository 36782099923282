import { useEffect, useState } from "react";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import axios from "axios";
import Swal from "sweetalert2";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendContact = async () => {
	setLoading(true);
    await axios
      .post(`http://county.jua.co.ke/api/email`, {
		name: name,
		email: email,
		phone: phone,
		message: message,
	  }, {})
      .then((response) => {
		setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Your message has been sent",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
		setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  return (
    <>
      <Header page={"Contact"} />
      <section class="ls">
        <div class="container">
          <div class="row">
            <div class="divider-40 divider-lg-150"></div>

            <div class="col-lg-6  mt-35" data-animation="pullDown">
              <h3 class="title_block">
                <span>Our</span> Contacts
              </h3>
              {/* <p class="pb-25">We provide free pick up and drop off at home or school for all behind-the-wheel lessons. And, we offer a payment plan at no additional charge. </p> */}

              <div class="row">
                <div class="col-sm-6">
                  <div class="icon-box">
                    <div class="media">
                      <div class="icon-styled color-main fs-40">
                        <i class="ico icon-hours"></i>
                      </div>

                      <div class="media-body">
                        <h6 class="fw-300">Phone 24/7</h6>
                        <p>
                          0795 080 085
                          <br />
                          0795 079 340
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="icon-box">
                    <div class="media">
                      <div class="icon-styled color-main fs-40">
                        <i class="ico icon-location"></i>
                      </div>

                      <div class="media-body">
                        <h6 class="fw-300">Our address</h6>
                        <p>
                          ST LUKES COMPLEX MAKONGENI OFF GARISSA ROAD P.O. BOX
                          4353-01002
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="divider-30 d-block d-sm-none"></div>
                <div class="col-sm-6">
                  <div class="icon-box">
                    <div class="media">
                      <div class="icon-styled color-main fs-40">
                        <i class="ico icon-clock"></i>
                      </div>

                      <div class="media-body">
                        <h6 class="fw-300">Operating Hours</h6>
                        <p>
                          Mon-Fri: 9:00 am - 5:00 pm Sat-Sun: 11:00 am - 16:00
                          pm
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="icon-box">
                    <div class="media">
                      <div class="icon-styled color-main fs-40">
                        <i class="ico icon-email"></i>
                      </div>

                      <div class="media-body">
                        <h6 class="fw-300">Email address</h6>
                        <p>ctcplantds@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider-40 d-lg-none d-md-block"></div>
            <div
              class="padding-mobile-small col-lg-6 cs px-70 py-66"
              data-animation="scaleAppear"
            >
              <form class="contact-form c-mb-10 c-gutter-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h4 class="title_block">
                      Email<span> Us</span>
                    </h4>

                    <div class="form-group has-placeholder">
                      <label for="name">
                        Full Name <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        aria-required="true"
                        size="30"
                        value={name}
                        name="name"
                        id="name"
                        class="form-control"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Full Name"
                      />
                    </div>
                    <div class="form-group has-placeholder">
                      <label for="email">
                        Email address<span class="required">*</span>
                      </label>
                      <input
                        type="email"
                        aria-required="true"
                        size="30"
                        value={email}
                        name="email"
                        id="email"
                        class="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>

                    <div class="form-group has-placeholder">
                      <label for="phone">
                        Phone Number<span class="required">*</span>
                      </label>
                      <input
                        type="phone"
                        aria-required="true"
                        size="30"
                        value={phone}
                        name="phone"
                        id="phone"
                        class="form-control"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone Number"
                      />
                    </div>

                    <div class="form-group has-placeholder">
                      <label for="message">Message</label>
                      <textarea
                        aria-required="true"
                        rows="6"
                        cols="45"
                        name="message"
                        id="message"
                        class="form-control"
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Message"
                      >
                        {message}
                      </textarea>
                    </div>
                  </div>
                </div>
                {!loading && (<div class="row mt-30">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <button
                        type="button"
                        onClick={() => sendContact()}
                        id="contact_form_submit"
                        name="contact_submit"
                        class="btn btn-outline-darkgrey"
                      >
                        Email Us
                      </button>
                    </div>
                  </div>
                </div>)}
				{loading && (<div class="row mt-30">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <button
                        type="button"
						disabled={true}
                        id="contact_form_submit"
                        name="contact_submit"
                        class="btn btn-outline-darkgrey"
                      >
                        Loading
                      </button>
                    </div>
                  </div>
                </div>)}
              </form>
            </div>
            {/* <!--.col-* --> */}

            <div class="divider-100 divider-lg-130"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default Contact;
