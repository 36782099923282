import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer class="page_footer ds s-pt-77 s-pb-60 c-gutter-60">
				<div class="container">
					<div class="row">
						<div class="divider-20 d-none d-xl-block"></div>

						<div class="col-md-6 col-xl-4" data-animation="fadeInUp">

							<div class="widget widget_text ">

								{/* <img src="images/logo.png" alt="" /> */}
								<p>
									County to County Plant Machinery Driving School aims at providing high quality driver education tailored to our customers‘ individual need, in combination with professional customer service.
								</p>
								<p class="copyright"><i>ctcplantds Copyright <span class="copyright_year">&copy;2022</span></i></p>
							</div>
						</div>

						<div class="col-md-6 col-xl-2" data-animation="fadeInUp">
							<div class="widget widget_working_hours">
								<h3>Company</h3>
								<ul class="list-not-style">

									<li>
										<Link to="/about">About us</Link>
									</li>

									<li>
										<Link to="/services">Services</Link>
									</li>

									<li>
										<Link to="/feestructure">Fee Structure</Link>
									</li>

									<li>
										<Link to="/gallery">Gallery</Link>
									</li>

									<li>
										<Link to="/contacts">Contact us</Link>
									</li>

								</ul>
							</div>
						</div>


						<div class="col-md-6 col-xl-3" data-animation="fadeInUp">
							<div class="widget widget_icons_list">
								<h3>Contacts</h3>

								<div class="media side-icon-box">
									<div class="icon-styled color-main fs-14">
										<i class="ico icon-facebook-placeholder-for-locate-places-on-maps"></i>
									</div>
									<p class="media-body">ST LUKES COMPLEX MAKONGENI OFF GARISSA ROAD P.O. BOX 4353-01002</p>
								</div>
								<div class="media side-icon-box">
									<div class="icon-styled color-main fs-14">
										<i class="ico icon-phone-receiver"></i>
									</div>
									<p class="media-body">Tel.: (254) 758 195 331</p>
								</div>
								<div class="media side-icon-box">
									<div class="icon-styled color-main fs-14">
										<i class="fa fa-envelope"></i>
									</div>
									<p class="media-body">
										<a href="#"><span class="">ctcplantds@gmail.com</span></a>
									</p>
								</div>

							</div>
						</div>

						<div class="col-md-6 col-xl-3" data-animation="fadeInUp">
							<div class="widget widget_mailchimp">

								<h3 class="widget-title">Subscribe</h3>

								<p>
									Get latest updates and offers
								</p>

								<form class="signup">
									<label for="mailchimp_email">
										<span class="screen-reader-text">Subscribe:</span>
									</label>

									<input id="mailchimp_email" name="email" type="email" class="form-control mailchimp_email" placeholder="Enter Your E-mail" />

									<button type="submit" class="search-submit">
										<span class="screen-reader-text">Subscribe</span>
									</button>
									<div class="response"></div>
								</form>

							</div>
							<div class="row c-gutter-30">
								<div class="col-sm-12 col-xl-6">
									<a href="https://www.facebook.com/countytocountyplantoperator" class="fa fa-facebook" title="facebook"><span>facebook</span></a>
									<a href="https://twitter.com/countyplant" class="fa fa-twitter" title="twitter"><span>twitter</span></a>
								</div>
								<div class="col-sm-12 col-xl-6">
									<a href="#" class="fab fa-linkedin-in" title="linkedin"><span>linkedin</span></a>
									<a href="#" class="fa fa-google" title="google"><span>google</span></a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</footer>
    )
}
export default Footer;