import Gallery1 from './images/gallery1.jpg'
import Gallery2 from './images/gallery2.jpg'
import Gallery3 from './images/gallery3.jpg'
import Gallery4 from './images/gallery4.jpg'
import Gallery5 from './images/gallery5.jpg'
import Gallery6 from './images/gallery6.jpg'
import Gallery7 from './images/gallery7.jpg'
import Gallery8 from './images/gallery8.jpg'
import Gallery9 from './images/gallery9.jpg'
import Gallery10 from './images/gallery10.jpg'
import Gallery11 from './images/gallery11.jpg'
import Gallery12 from './images/gallery12.jpg'
import Gallery13 from './images/gallery13.jpg'
import Gallery14 from './images/gallery14.jpg'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import ReactImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
// import './gallery.css'
function Gallery() {

    const images = [
        {
          original: `${Gallery1}`,
          thumbnail: `${Gallery1}`,
        },
        {
          original: `${Gallery2}`,
          thumbnail: `${Gallery2}`,
        },
        {
          original: `${Gallery3}`,
          thumbnail: `${Gallery3}`,
        },
        {
            original: `${Gallery4}`,
            thumbnail: `${Gallery4}`,
        },
        {
            original: `${Gallery5}`,
            thumbnail: `${Gallery5}`,
        },
        {
            original: `${Gallery6}`,
            thumbnail: `${Gallery6}`,
        },
        {
            original: `${Gallery7}`,
            thumbnail: `${Gallery7}`,
        },
        {
            original: `${Gallery8}`,
            thumbnail: `${Gallery8}`,
        },
        {
            original: `${Gallery9}`,
            thumbnail: `${Gallery9}`,
        },
        {
            original: `${Gallery10}`,
            thumbnail: `${Gallery10}`,
        },
        {
            original: `${Gallery11}`,
            thumbnail: `${Gallery11}`,
        },
        {
            original: `${Gallery12}`,
            thumbnail: `${Gallery12}`,
        },
        {
            original: `${Gallery13}`,
            thumbnail: `${Gallery13}`,
        },
        {
            original: `${Gallery14}`,
            thumbnail: `${Gallery14}`,
        },
      ];

    return (
        <>
            <Header page={'Gallery'}/>
                <section class="ls s-py-50 container-px-5">
                    
                    <div class="container-fluid">
                        <div class="row">

                            <div class="divider-70 d-none d-lg-none"></div>

                            <div class="col-lg-12">
                                <ReactImageGallery 
                                items={images} 
                                showNav={true}
                                />
                            </div>

                            <div class="divider-xl-100 divider-50"></div>
                        </div>

                    </div>
                </section>
            <Footer />
        </>
        
    )
}
export default Gallery;