import maleAvatar from './image/maleAvatar.png'
import femaleAvatar from './image/femaleAvatar.png'
function Testimonial() {
    return (
        <section id="section_testimonials" class="container-fluids-mw ls">
            <div class="container-fluid">
                <div class="row c-gutter-50 mobile-padding-normal">
                    <div class="divider-70 divider-xl-140"></div>
                    <div class="col-md-12">
                        <div class="text-center">
                            <h3>What <span class="color-main">Clients Says</span><br />About Us</h3>
                            <p class="after-title subtitle width-xl-50 width-100">
                                
                            </p>
                        </div>

                        <div class="testimonials-slider owl-carousel mt-60" data-autoplay="true" data-loop="true" data-responsive-lg="3" data-responsive-md="3" data-responsive-sm="1" data-responsive-xs="1" data-nav="false" data-dots="false" data-margin="100" data-center="true">
                            <div class="quote-item">
                                <div class="quote-image">
                                    <img src={maleAvatar} alt="" />
                                </div>
                                <p>
                                    <i class="ico icon-left-quote"></i>
                                    <em>
                                        The school was great. Everything asked for was provided. Really enjoyed their services.
                                    </em>
                                </p>
                                <footer>
                                    <cite class="color-dark">Joseph Kinyua Mothee</cite>
                                    <span class="color-main">Student</span>
                                </footer>
                                {/* <!--<p class="color-darkgrey">-->
                                <!--Sidney W. Yarber-->
                                <!--</p>-->
                                <!--<p class="color-main">-->
                                <!--Manager-->
                                <!--</p>--> */}
                            </div>


                            <div class="quote-item">
                                <div class="quote-image">
                                    <img src={maleAvatar} alt="" />
                                </div>
                                <p>
                                    <i class="ico icon-left-quote"></i>
                                    <em>
                                        The school was good. Understood all the machines well.
                                    </em>
                                </p>
                                <footer>
                                    <cite class="color-dark">Nathaniel Muturi Kiburia</cite>
                                    <span class="color-main">Student</span>
                                </footer>
                            </div>


                            <div class="quote-item">
                                <div class="quote-image">
                                    <img src={femaleAvatar} alt="" />
                                </div>
                                <p>
                                    <i class="ico icon-left-quote"></i>
                                    <em>
                                    Best treatment ever. Best experience.
                                    </em>
                                </p>
                                <footer>
                                    <cite class="color-dark">Esther Mukami</cite>
                                    <span class="color-main">Student</span>
                                </footer>
                            </div>

                            <div class="quote-item">
                                <div class="quote-image">
                                    <img src={femaleAvatar} alt="" />
                                </div>
                                <p>
                                    <i class="ico icon-left-quote"></i>
                                    <em>
                                    Good experience. They taught everything. Great Services.
                                    </em>
                                </p>
                                <footer>
                                    <cite class="color-dark">Sharon Muthoni Mungai</cite>
                                    <span class="color-main">Student</span>
                                </footer>
                            </div>

                            <div class="quote-item">
                                <div class="quote-image">
                                    <img src={maleAvatar} alt="" />
                                </div>
                                <p>
                                    <i class="ico icon-left-quote"></i>
                                    <em>
                                    School was excellent. Got a job immediately. Highly recommend.
                                    </em>
                                </p>
                                <footer>
                                    <cite class="color-dark">Amos Kabugi Kimani</cite>
                                    <span class="color-main">Student</span>
                                </footer>
                            </div>

                        </div>
                        {/* <!-- .testimonials-slider --> */}
                    </div>
                    <div class="divider-50 divider-xl-120"></div>
                </div>
            </div>
        </section>
    )
}
export default Testimonial;