import { Link } from "react-router-dom";
import Slide1 from "./images/slider1.jpg";
import Slide2 from "./images/slider2.jpg";
import Slide3 from "./images/slider3.jpg";
function Slider() {
  return (
    <section class="page_slider">
      <div class="flexslider">
        <ul class="slides">
          <li class="cs cover-image flex-slide">
            <img src={Slide1} alt="" />
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="intro_layers_wrapper">
                    <div class="intro_layers">
                      <div class="intro_layer" data-animation="fadeInRight">
                        <h1>Teaching you to</h1>
                        <h1 class="after-title">
                          <span> Take Control</span>
                        </h1>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <ul class="slider-list">
                          <li>
                            As a driver, you must realize that having confidence
                            on the road is a must. If you lack confidence, you
                            are more likely to make mistakes, which might lead
                            to accidents. As a result, it's vital to remember
                            that training can help you stay confident in any
                            situation.
                          </li>
                          {/* <li>The right driving school is accredited according to state laws and will prepare you to be a safe.</li>
                                                    <li>When you first start learning to drive, it can seem a little overwhelming. We know that, but don't worry.</li>
                                                    <li>Practice makes perfect! We know you've heard that before, but it's especially true with driving.</li> */}
                        </ul>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <Link
                          to="/contacts"
                          class="text-uppercase btn btn-outline-maincolor"
                        >
                          Get Started
                        </Link>
                        {/* <div class="slider-price-wrap">
                                                    <span class="plan-price"></span>
                                                    <span class="plan-decimals"></span>
                                                    <span class="plan-sign">usd</span>
                                                </div> */}
                        {/* <div class="slider-price-description">
                                                    <p class="slider-price-description-one">We offer hostels</p>
                                                    <p class="slider-price-description-two">& accommodations</p>
                                                </div> */}
                      </div>
                    </div>
                    {/* <!-- eof .intro_layers --> */}
                  </div>
                  {/* <!-- eof .intro_layers_wrapper --> */}
                </div>
                {/* <!-- eof .col-* --> */}
              </div>
              {/* <!-- eof .row --> */}
            </div>
            {/* <!-- eof .container-fluid --> */}
          </li>
          <li class="cs cover-image flex-slide">
            <img src={Slide1} alt="" />
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="intro_layers_wrapper">
                    <div class="intro_layers">
                      <div class="intro_layer" data-animation="fadeInRight">
                        <h1>We offer </h1>
                        <h1 class="after-title">
                          <span> hostel and accommodation</span>
                        </h1>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <ul class="slider-list">
                          <li>
                            We offer hostels and accommodations for our students to make it easier for them to learn. This increases their productivity and makes them more comfortable.
                          </li>
                          {/* <li>The right driving school is accredited according to state laws and will prepare you to be a safe.</li>
                                                    <li>When you first start learning to drive, it can seem a little overwhelming. We know that, but don't worry.</li>
                                                    <li>Practice makes perfect! We know you've heard that before, but it's especially true with driving.</li> */}
                        </ul>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <Link
                          to="/contacts"
                          class="text-uppercase btn btn-outline-maincolor"
                        >
                          Get Started
                        </Link>
                        {/* <div class="slider-price-wrap">
                                                    <span class="plan-price"></span>
                                                    <span class="plan-decimals"></span>
                                                    <span class="plan-sign">usd</span>
                                                </div> */}
                        {/* <div class="slider-price-description">
                                                    <p class="slider-price-description-one">We offer hostels</p>
                                                    <p class="slider-price-description-two">& accommodations</p>
                                                </div> */}
                      </div>
                    </div>
                    {/* <!-- eof .intro_layers --> */}
                  </div>
                  {/* <!-- eof .intro_layers_wrapper --> */}
                </div>
                {/* <!-- eof .col-* --> */}
              </div>
              {/* <!-- eof .row --> */}
            </div>
            {/* <!-- eof .container-fluid --> */}
          </li>

          <li class="cs cover-image flex-slide">
            <img src={Slide2} alt="" />
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="intro_layers_wrapper intro_text_bottom">
                    <div class="intro_layers">
                      <div class="intro_layer" data-animation="fadeInRight">
                        <h1>We don't follow</h1>
                        <h1 class="after-title">
                          <span> We lead</span>
                        </h1>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <ul class="slider-list">
                          <li>
                            Continue to deliver quality information in our
                            training standards and methodology.
                          </li>
                          <li>
                            Understand customer’s needs better and deliver them
                            in the most friendly and reliable quality service.
                          </li>
                          <li>
                            Enable road safety by creating safer and better
                            skilled drivers.
                          </li>
                        </ul>
                      </div>

                      <div class="intro_layer" data-animation="fadeInUp">
                        <Link
                          to="/contacts"
                          class="text-uppercase btn btn-outline-maincolor"
                        >
                          Get Started
                        </Link>
                        {/* <div class="slider-price-wrap">
                                                    <span class="plan-price">73</span>
                                                    <span class="plan-decimals">95</span>
                                                    <span class="plan-sign">usd</span>
                                                </div>
                                                <div class="slider-price-description">
                                                    <p class="slider-price-description-one">Includes Free Court</p>
                                                    <p class="slider-price-description-two">& DMV Submission</p>
                                                </div> */}
                      </div>
                    </div>
                    {/* <!-- eof .intro_layers --> */}
                  </div>
                  {/* <!-- eof .intro_layers_wrapper --> */}
                </div>
                {/* <!-- eof .col-* --> */}
              </div>
              {/* <!-- eof .row --> */}
            </div>
            {/* <!-- eof .container-fluid --> */}
          </li>
          <li class="cs cover-image flex-slide">
            <img src={Slide3} alt="" />
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="intro_layers_wrapper intro_text_bottom">
                    <div class="intro_layers">
                      <div class="intro_layer" data-animation="fadeInRight">
                        <h1>An Investment</h1>
                        <h1 class="after-title">
                          <span> In Safety</span>
                        </h1>
                      </div>
                      <div class="intro_layer" data-animation="fadeInUp">
                        <ul class="slider-list">
                          <li>
                            Driving is a necessary skill that many people must
                            learn. Enrolling in a driving school that may
                            provide appropriate training to make you a safe
                            driver is one approach to earning properly.
                          </li>
                          {/* <li>To remove and dust off your rust when it comes to driving</li>
                                                    <li>Defensive drivers are able to avoid dangers on the road by using their safe driving practices.</li>
                                                    <li>Training in various plant machinery.</li> */}
                        </ul>
                      </div>

                      <div class="intro_layer" data-animation="fadeInUp">
                        <Link
                          to="/contacts"
                          class="text-uppercase btn btn-outline-maincolor"
                        >
                          Get Started
                        </Link>
                        {/* <div class="slider-price-wrap">
                                                    <span class="plan-price">73</span>
                                                    <span class="plan-decimals">95</span>
                                                    <span class="plan-sign">usd</span>
                                                </div>
                                                <div class="slider-price-description">
                                                    <p class="slider-price-description-one">Includes Free Court</p>
                                                    <p class="slider-price-description-two">& DMV Submission</p>
                                                </div> */}
                      </div>
                    </div>
                    {/* <!-- eof .intro_layers --> */}
                  </div>
                  {/* <!-- eof .intro_layers_wrapper --> */}
                </div>
                {/* <!-- eof .col-* --> */}
              </div>
              {/* <!-- eof .row --> */}
            </div>
            {/* <!-- eof .container-fluid --> */}
          </li>
        </ul>
      </div>
      {/* <!-- eof flexslider --> */}
    </section>
  );
}
export default Slider;
