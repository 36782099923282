import Footer from "../../layout/footer";
import Header from "../../layout/header";
import HomeHeader from "../../layout/homeHeader";
import CountDown from "./countdown";
import Courses from "./courses";
import FeeStructure from "./feestructure";
import Slider from "./slider";
import Started from "./started";
import Tabs from "./tabs";
import Testimonial from "./testimonials";
import Video from "./video";
import Welcome from "./welcome";

function Home() {
    return (
        <>
			<Header />
				<Slider />
				<Tabs />
				<Welcome />
				<FeeStructure />
				<Video />
				<Courses />				
				<Testimonial />
				<CountDown />
				<Started />
			<Footer />
        </>
    )
}
export default Home;