function Tabs() {
    return (
        <section class="ls teaser-box-section">
				<div class="container">
					<div class="row c-gutter-8">
						<div class="col-sm-4">
							<a class="text-center py-45 box-shadow mb-20 cs teaser" href="#">
								<h6 class="fw-300"><i class="ico icon-steering-wheel fs-40 px-10"></i>New Programms</h6>
							</a>
						</div>
						<div class="col-sm-4">
							<a class="text-center py-45 box-shadow mb-20 teaser" href="#">
								<h6 class="fw-300"><i class="ico icon-professor fs-40 px-10"></i>Expert teachers</h6>
							</a>
						</div>
						<div class="col-sm-4">
							<a class="text-center py-45 box-shadow mb-20 teaser" href="#">
								<h6 class="fw-300"><i class="ico icon-mortarboard fs-40 px-10"></i>Education System</h6>
							</a>
						</div>
					</div>
				</div>
			</section>
    )
}
export default Tabs