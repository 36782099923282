import { Link } from "react-router-dom";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

function Services() {
    return (
        <>
            <Header page={'Our Services'}/>
            <section class="ls s-py-sm-50 c-gutter-60 c-mb-50">
				<div class="container">
					<div class="row">

						<div class="divider-80 divider-sm-40 d-lg-70"></div>

						<div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									{/* <i class="color-main fa fa-superpowers"></i> */}
									<img src="https://img.icons8.com/dotty/80/000000/driver-license-card.png"/>
								</div>

								<h6 class="fw-300">
									<Link to="">NTSA Based Curriculum</Link>
								</h6>

								<p>
                                    Motocycles, Light Vehicles, Light Trucks, Prime Movers, Buses/ Minibuses.
								</p>


							</div>
						</div>
                        {/* <!-- .col-* --> */}
						<div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									{/* <i class="color-main fa fa-bank"></i> */}
									<img src="https://img.icons8.com/dotty/80/000000/driving.png"/>
								</div>

								<h6 class="fw-300">
									<Link to="">Defensive Driving Training</Link>
								</h6>

								<p>
                                    Defensive drivers are able to avoid dangers on the road by using their safe driving practices.
								</p>


							</div>
						</div>
                        {/* <!-- .col-* --> */}
						<div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									{/* <i class="color-main fa fa-area-chart"></i> */}
									<img src="https://img.icons8.com/dotty/80/000000/interstate-truck.png"/>
								</div>

								<h6 class="fw-300">
									<a href="service-single.html">Training in various plant machineries</a>
								</h6>

								<p>
                                    Roller, Forklift, Bulldozer, Shovel, Excavator, Grader.
								</p>


							</div>
						</div>
                        {/* <!-- .col-* --> */}
						<div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									{/* <i class="color-main fa fa-bar-chart-o"></i> */}
									<img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-courses-recruitment-agency-flaticons-lineal-color-flat-icons.png"/>
								</div>

								<h6 class="fw-300">
									<Link to="">Refresher courses</Link>
								</h6>

								<p>
                                    To remove and dust off your rust when it comes to driving
								</p>


							</div>
						</div>
                        {/* <!-- .col-* --> */}
						{/* <div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									<i class="color-main fa fa-building-o"></i>
								</div>

								<h6 class="fw-300">
									<Link to="">Stock</Link>
								</h6>

								<p>
									At vero eos et accusam et justo duo dolores et ea rebum
								</p>


							</div>
						</div> */}
                        {/* <!-- .col-* --> */}
						{/* <div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									<i class="color-main fa fa-briefcase"></i>
								</div>

								<h6 class="fw-300">
									<Link to="">Business Planning</Link>
								</h6>

								<p>
									Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
								</p>


							</div>
						</div> */}
                        {/* <!-- .col-* --> */}
						{/* <div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									<i class="color-darkgrey fa fa-money"></i>
								</div>

								<h6 class="fw-300">
									<Link to="">Trades</Link>
								</h6>

								<p>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
								</p>


							</div>
						</div> */}
                        {/* <!-- .col-* --> */}
						{/* <div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									<i class="color-main2 fa fa-handshake-o"></i>
								</div>

								<h6 class="fw-300">
									<Link to="">Retirement Planning</Link>
								</h6>

								<p>
									Nonumy eirmod tempor invidunt ut labore et dolore
								</p>


							</div>
						</div> */}
                        {/* <!-- .col-* --> */}
						{/* <div class="col-md-4 col-sm-6">

							<div class="icon-box text-center">
								<div class="icon-styled fs-40">
									<i class="color-main fa fa-globe"></i>
								</div>

								<h6 class="fw-300">
									<Link to="">Investment</Link>
								</h6>

								<p>
									Magna aliquyam erat, sed diam voluptua. At vero eos et accusam
								</p>


							</div>
						</div> */}
                        {/* <!-- .col-* --> */}

						<div class="divider-30 divider-sm-0 divider-lg-0 divider-xl-0"></div>

					</div>

				</div>
			</section>
            <Footer />
        </>
    )
}
export default Services;