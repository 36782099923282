import { Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import FeeStructure from './pages/feestructure';
import Gallery from './pages/gallery';
import Home from './pages/home';
import Services from './pages/services';

function App() {
  return (
    <div id='canvas'>
      <div id='box_wrapper'>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/services' element={<Services />} />
        <Route exact path='/feestructure' element={<FeeStructure />} />
        <Route exact path='/gallery' element={<Gallery />} />
        <Route exact path='/contacts' element={<Contact />} />
      </Routes>
      </div>
    </div>
  );
}

export default App;
