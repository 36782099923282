function CountDown() {
    return (
        <section id="countdown-section" class="ds">
            <div class="container">
                <div class="row c-gutter-50 mobile-padding-normal">
                    <div class="divider-70 divider-xl-90"></div>

                    <div class="col-sm-12 col-md-6 col-lg-3 text-center">
                        <h3 class="counter-wrap color-main">
                            <span class="counter" data-from="0" data-to="1489" data-speed="1500">0</span>
                            <span class="counter-add">+</span>
                        </h3>

                        <p>Graduates received the right</p>
                    </div>

                    <div class="divider-40 d-sm-block d-md-none"></div>

                    <div class="col-sm-12 col-md-6 col-lg-3 text-center">
                        <h3 class="counter-wrap color-main">
                            <span class="counter" data-from="0" data-to="5" data-speed="2500">0</span>
                        </h3>

                        <p>Years on the market</p>
                    </div>

                    <div class="divider-40 d-md-block d-lg-none "></div>

                    <div class="col-sm-12 col-md-6 col-lg-3 text-center">
                        <h3 class="counter-wrap color-main">
                            <span class="counter" data-from="0" data-to="96" data-speed="3000">0</span>
                        </h3>

                        <p>Training hours</p>
                    </div>

                    <div class="divider-40 d-sm-block d-md-none"></div>

                    <div class="col-sm-12 col-md-6 col-lg-3 text-center">
                        <h3 class="counter-wrap color-main">
                            <span class="counter" data-from="0" data-to="10" data-speed="2800">0</span>
                        </h3>

                        <p>Number of teachers</p>
                    </div>

                    <div class="divider-80 divider-xl-100"></div>
                </div>
            </div>
        </section>
    )
}
export default CountDown;