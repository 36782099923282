import InstructorImage from '../images/instructor.jpg'
function Team() {
	return (
		<>
			<section class="ls s-py-50">
				<div class="container">
					<div class="row">


						<div class="divider-30 divider-xl-95"></div>

						<div class="col-12">
							<div class="row c-gutter-50">


								<div class="col-md-5">
									<div>
										<img src={InstructorImage} alt="" />
									</div>
								</div>

								<div class="col-md-7">
									<div class="divider-30 divider-md-0 divider-xl-0"></div>
									<h3 class="mt-0"><span class="color-main">Our </span> Instructors</h3>
									{/* <p class="entry-description-profession divider-top-bottom">Autor courses</p> */}

									<p>
										Our Instructors for both theory and practical are licenced and certified by the national transport and safety authority (NTSA) and the Kenya plant operators association (KPOA).
										They are trained on a regular basis to keep them motivated by using the latest international drivers education curriculums and traffic news updates. County to county plant machinery and driving school has a team of highly 
										dedicated instructors, where you feel safe and comfortable with trained efficient instructors and staff.
									</p>

								</div>

							</div>


						</div>


					</div>
				</div>
			</section>
		</>
	)
}
export default Team;