import InstructorImage from './images/instructor.jpg'
function Courses() {
	return (
		<section class="ls s-py-50">
			<div class="container">
				<div class="row">


					<div class="divider-30 divider-xl-95"></div>

					<div class="col-12">
						<div class="row c-gutter-50">


							<div class="col-md-5">
								<div>
									<img src={InstructorImage} alt="" />
								</div>
							</div>

							<div class="col-md-7">
								<div class="divider-30 divider-md-0 divider-xl-0"></div>
								<h3 class="mt-0"><span class="color-main">Courses </span> Taken</h3>
								{/* <p class="entry-description-profession divider-top-bottom">Autor courses</p> */}

								<p>
									At County to county plant machinery and driving school we provide driver licence acquisition of vehicles in different vehicle categories namely:
									<strong>Motorcycle, Tuk Tuk, Light Motor Vehicle (Saloon Car), Automatic, Professional Drivers, 14-Seater Matatus, 33-Seater Minibus, Buses, PSV Special, Defensive Driving, Light Truck, Medium Truck and Trailer.</strong>
								</p>

								<p>
									The driving tuition standards are set at international levels and tuition methods are continuously updated to make use of the latest driving techniques advised by the Ministry of interior through National Transport and Safety Authority(NTSA).
									When you learn with us at county to county plant machinery and driving school, you can rest assured that your driving licence is within close reach for we have set superior standards.
								</p>

								{/* <p>
									Over nine hundred thousand students have acquired their driving licences from county to county plant machinery since its establishment. We have qualified instructors of high caliber who instruct the studentss hands-on making it easier for them to learn.
									That's why where ever you go all you hear of is: <strong>"I trained at county to county plant machinery and driving school!"</strong>
								</p>

								<p>
									Our dedicated driving instructors have immense experience in teaching new learners safe driving skills and habits in accordance with the syllabus administered by the NTSA. We strinve to maintain the highest quality standards and are committed to teaching driving
									skills that promote road safety.
								</p>

								<p>
									At county to county plant machinery and driving school, it is believed that in the pursuit of quality and excellence there is no finishing line. That's why we've kept on building a reputable brand that you can be proud of and has spread to our neighboring countries.
									We have professional, experienced and ethical staff members to ensure complete satisfaction for all our students/clients from far and wide.
								</p>

								<p>
									Our vehicles are all insured through an established insurance company. Besides, they are all mechanically inspected annually to ensure they are road worthy. Immediately on confirmation of commencement of lessons, students are given their appropriate theory or practical lesson
									bookings, as per their requirements. There is the procedure that we follow and a student can only commence their practical test after being issued with a provisional driving licence, in accordance with the NTSA rules and regulations.
								</p>

								<p>
									We give big discounts for group or companies wishing to undertake corporate training.
									Welcome to County-to-County Plant Machinery &amp; Driving School. We will train you Defensive
									Driving to drive safely and arrive alive. We don’t spare the cost to train our students to become the
									best.
								</p> */}

							</div>

						</div>


					</div>


				</div>
			</div>
		</section>
	)
}
export default Courses;