function Started() {
    return (
        <>
            <section class="ls s-py-70 s-py-xl-141">
                <div class="container">
                    <div class="row c-gutter-135 mobile-padding-normal">

                        <div class="col-md-12">
                            <div class="text-center mb-45">
                                <h3>How to <span class="color-main">get started on</span><br />Driving Lessons</h3>
                                <p class="subtitle width-100 width-xl-60">
                                    
                                </p>
                            </div>

                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <h1 class="color-main fw-500"><i class="ico icon-circle fs-8"></i>01</h1>
                                    <h6 class="color-dark mt-28 after-title2">Choosing A Driving School</h6>
                                    <p>The right driving school is accredited according to state laws and will prepare you to be a safe</p>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="divider-30 divider-md-0 divider-xl-0"></div>
                                    <h1 class="grey-color fw-500 mt-0"><i class="ico icon-circle fs-8"></i>02</h1>
                                    <h6 class="color-dark mt-28 after-title2">Driving Basics</h6>
                                    <p>When you first start learning to drive, it can seem a little overwhelming. We know that, but don't worry.</p>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="divider-30 divider-md-0 divider-xl-0"></div>
                                    <h1 class="grey-color fw-500 mt-0"><i class="ico icon-circle fs-8"></i>03</h1>
                                    <h6 class="color-dark mt-28 after-title2">Road Test Preparation</h6>
                                    <p>Practice makes perfect! We know you've heard that before, but it's especially true with driving.</p>
                                </div>
                            </div>
                        </div>
                        <div class="divider-5 d-lg-block d-xl-5"></div>
                    </div>
                </div>
            </section>
            <section id="information-block" class="ds s-pt-xl-90 s-pb-xl-94 s-pt-60 s-pb-60">
                <div class="container">
                    <div class="row c-gutter-50">

                        <div class="divider-10 divider-lg-10 divider-xl-5"></div>
                        <div class="col-md-9 col-lg-6 col-sm-12">
                            <h3 class="after-title">Just looking <span class="color-main">for lessons?</span></h3>

                            <p class="subtitle">Whether you are an existing driver or a new driver who needs help preparing for road test we have package options that can help you.</p>
                            <div class="mt-45">
                                <button type="button" class="btn btn-outline-darkgrey small fw-400">Get Started</button><span class="m-25"> or </span><a href="#" class="btn btn-link">Learn more</a>
                            </div>
                            <div class="divider-20 divider-lg-20 divider-xl-5"></div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
export default Started;