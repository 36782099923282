function FeeStructure() {
    return (
        <section id="price" class="ls">
            <div class="container">
                <div class="row">
                    <div class="divider-70 divider-xl-140"></div>

                    {/* <div class="col-md-12">
                        <div class="text-center">
                            <h3><span class="color-main">Drively</span> Packages</h3>
                            <p class="subtitle width-xl-60 width-100">The school offers the following services for Teenage first-time drivers, new adult learners and existing drivers with lapsed licenses.</p>
                        </div>

                        <div class="row c-gutter-0">
                            <div class="divider-30 divider-md-60 divider-xl-64"></div>
                            <div class="col-lg-4 col-md-4">
                                <div class="pricing-plan box-shadow">
                                    <div class="plan-name">
                                        <h3>
                                            Beginner Driving Courses
                                        </h3>
                                    </div>
                                    <div class="price-wrap color-main">
                                        <span class="plan-price">49</span><span class="plan-decimals">95</span><span class="plan-sign">usd</span>
                                    </div>
                                    <div class="plan-description">
                                        4% per transaction
                                    </div>
                                    <div class="plan-features">
                                        <ul class="list-styled-caret">
                                            <li>5 1-hour lessons</li>
                                            <li>FREE choice of instructor</li>
                                            <li>FREE final exam</li>
                                        </ul>
                                    </div>
                                    <div class="plan-button">
                                        <a href="#" class="btn btn-outline-darkgrey">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">

                                <div class="pricing-plan plan-featured box-shadow">
                                    <div class="plan-name">
                                        <h3>
                                            Beginner Driving Courses
                                        </h3>
                                    </div>
                                    <div class="price-wrap">
                                        <span class="plan-price">73</span><span class="plan-decimals">95</span><span class="plan-sign">usd</span>
                                    </div>
                                    <div class="plan-description color-darkgrey">
                                        0,2% per transaction
                                    </div>
                                    <div class="plan-features">
                                        <ul class="list-styled-caret">
                                            <li>5 1-hour lessons</li>
                                            <li>FREE choice of instructor</li>
                                            <li>FREE final exam</li>
                                            <li>Private In-Car Instruction</li>
                                            <li>Pre and Post Lesson follow Up</li>
                                        </ul>
                                    </div>
                                    <div class="plan-button">
                                        <a href="#" class="btn btn-outline-darkgrey">Get Started</a>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-4 col-md-4 mx-sm-auto">

                                <div class="pricing-plan box-shadow">
                                    <div class="plan-name">
                                        <h3>
                                            Beginner Driving Courses
                                        </h3>
                                    </div>
                                    <div class="price-wrap color-main">
                                        <span class="plan-price">92</span><span class="plan-decimals">95</span><span class="plan-sign">usd</span>
                                    </div>
                                    <div class="plan-description">
                                        0,2% per transaction
                                    </div>
                                    <div class="plan-features">
                                        <ul class="list-styled-caret">
                                            <li>5 1-hour lessons</li>
                                            <li>FREE choice of instructor</li>
                                            <li>FREE final exam</li>
                                        </ul>
                                    </div>
                                    <div class="plan-button">
                                        <a href="#" class="btn btn-outline-darkgrey">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="divider-55 divider-md-20 divider-xl-90"></div>
                        </div>

                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default FeeStructure;