import Footer from "../../layout/footer";
import Header from "../../layout/header";
import AboutImage from './images/about.jpg'
import Team from "./team";

function About() {
    return (
        <>
            <Header page={'About Us'}/>
                <div class="divider-20 d-none d-xl-block"></div>
                <section class="ls about_section">
                    <div class="container">
                        <div class="row">

                            <div class="divider-20"></div>

                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="divider-50 d-lg-block"></div>
                                        <h3 class="width-80 mt-0"><span class="color-main">Welcome</span> to ctcplantds Traffic School!</h3>
                                        <p class="after-title subtitle">
                                            We are a reputable driving school located at Makongeni in Thika in Kiambu County.
                                        </p>
                                        <p>
                                            County to County Plant Machinery and Driving School has rapidly expanded its mouth-watering name in Kenya, since its ineption to become the trailblazers in the Plant Operator and driver training industry. 
                                            Our roots began with a vision to provide excellent services in both Plant Operator and Driving to our customers and empower, equip the youth and all the trainees with excellent knowledge and work-related skills.
                                            We have grown over the years and have trained trainees from over 70% counties in our republic. We have both Plant and Motor vehicle mechanics trainees trained by our qualified, proficient and professional instructors. 
                                            We are and NTSA accredied institution with professional instructors
                                        </p>
                                        <div class="divider-40 divider-sm-50 divider-md-80"></div>
                                        <div class="row c-gutter-30">
                                            <div class="divider-20 d-lg-none d-md-block"></div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="icon-box">
                                                    <div class="media">
                                                        <div class="icon-styled color-main fs-40">
                                                            <i class="ico icon-event"></i>
                                                        </div>

                                                        <div class="media-body">
                                                            <h6 class="fw-300">
                                                                Practical Training Approach
                                                            </h6>
                                                            <p>
                                                            To imitate a real-world work environment and give the trainees more exposure, we make significant investments in tools and equipment.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="divider-30 divider-lg-42"></div>
                                                <div class="icon-box">
                                                    <div class="media">
                                                        <div class="icon-styled color-main fs-40">
                                                            <i class="ico icon-medal"></i>
                                                        </div>

                                                        <div class="media-body">
                                                            <h6 class="fw-300">
                                                                Professional Trainers
                                                            </h6>
                                                            <p>
                                                            High professional training standards are what we uphold. Our instructors have pedagogical training in addition to industry-specific training.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="divider-30 d-lg-none d-md-block"></div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="divider-20 divider-md-0 divider-xl-0"></div>
                                                <div class="icon-box">
                                                    <div class="media">
                                                        <div class="icon-styled color-main fs-40">
                                                            <i class="ico icon-helping"></i>
                                                        </div>

                                                        <div class="media-body">
                                                            <h6 class="fw-300">
                                                                Industry Perspective
                                                            </h6>
                                                            <p>
                                                            We give our trainees exposure to actual industry initiatives and provide them with mentorship from working professionals.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="divider-30 divider-lg-42"></div>
                                                <div class="icon-box">
                                                    <div class="media">
                                                        <div class="icon-styled color-main fs-40">
                                                            <i class="ico icon-car-star"></i>
                                                        </div>

                                                        <div class="media-body">
                                                            <h6 class="fw-300">
                                                                Effective Management
                                                            </h6>
                                                            <p>
                                                            We think that excellent management is essential for successful institutions. We have a welcoming yet strict administrative culture at Ctcplantds Institute of Technology, guaranteeing that our trainees have a superb learning environment.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <img src={AboutImage} alt="" style={{marginLeft: '30px', marginTop: '150px'}}/>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <Team />
            <Footer />
        </>
    )
}
export default About;