function Video() {
    return (
        <section id="video" class="ls">

            <div class="cover-image s-cover-left"></div>
            {/* <!-- half image background element --> */}
            <div class="container">
                <div class="row align-items-center c-gutter-60">
                    <div class="col-md-12 col-lg-6">
                        <div class="item-media">
                            <div class="embed-responsive">
                                {/* <a href="images/square/home-video.html" class="photoswipe-link" data-width="800" data-height="800" data-iframe="//www.youtube.com/embed/mcixldqDIEQ"> */}
                                <a href="images/square/home-video.html" class="photoswipe-link" data-width="800" data-height="800">
                                    <img src="images/home-video.jpg" alt="" />
                                </a>
                            </div>
                            {/* <!-- <iframe width="1000" height="460" src="https://www.youtube.com/embed/mcixldqDIEQ" allowfullscreen></iframe> --> */}
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">


                        <div class="divider-30 divider-md-70 divider-xl-75"></div>


                        <h3 class="mt-0"><span class="color-main">Our Core</span> Values</h3>
                        <p class="after-title subtitle">
                            
                        </p>
                        <div class="row c-gutter-30">
                            <div class="col-md-12 col-lg-6">
                                <div class="icon-box">
                                    <div class="media">
                                        <div class="icon-styled color-main fs-24">
                                            <i class="ico icon-shield fs-40"></i>
                                        </div>

                                        <div class="media-body">
                                            <h6 class="fw-300">
                                                Quality Training
                                            </h6>
                                            <p>
                                            Continue to deliver quality information in our training standards and methodology.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider-30 divider-lg-42"></div>
                                <div class="icon-box">
                                    <div class="media">
                                        <div class="icon-styled color-main fs-24">
                                            <i class="ico icon-event fs-40"></i>
                                        </div>

                                        <div class="media-body">
                                            <h6 class="fw-300">
                                                Customer Centric
                                            </h6>
                                            <p>
                                                Understand customer’s needs better and deliver them in the most friendly and reliable quality service.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider-30 d-lg-none d-md-block"></div>
                            <div class="col-md-12 col-lg-6">
                                <div class="icon-box">
                                    <div class="media">
                                        <div class="icon-styled color-main fs-24">
                                            <i class="ico icon-steering-wheel fs-40"></i>
                                        </div>

                                        <div class="media-body">
                                            <h6 class="fw-300">
                                                Innovation and Leadership
                                            </h6>
                                            <p>
                                            Be the leading committed innovator in the market.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider-30 divider-lg-42"></div>
                                <div class="icon-box">
                                    <div class="media">
                                        <div class="icon-styled color-main fs-24">
                                            <i class="ico icon-credit-card fs-40"></i>
                                        </div>

                                        <div class="media-body">
                                            <h6 class="fw-300">
                                                Lead road safety
                                            </h6>
                                            <p>
                                            Enable road safety by creating safer and better skilled drivers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider-0 divider-md-0 divider-xl-75"></div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Video;